<template>
    <div class="customers" :class="{ 'data-left': !isBlock }">
        <div class="left">
            <span class="title">
                {{ overViewDataLeftTitle }}
            </span>
            <div class="left-wrap" @click="goCustomerList">
                <span class="num">{{ overViewDataLeftNum }}</span>
                <span class="text">个</span>
                <van-icon name="arrow" size="0.24rem" color="#333333" class="arrow" />
            </div>
        </div>
        <div class="center" v-if="isBlock"></div>
        <div class="right" v-if="isBlock">
            <span class="title">
                {{ overViewDataRightTitle }}
            </span>
            <!-- <div v-if="isGray" class="no-show">--</div> -->
            <div class="right-wrap" @click="goMerchantList">
                <span class="num">{{ overViewDataRightNum }}</span>
                <span class="text">个</span>
                <van-icon name="arrow" size="0.24rem" color="#333333" class="arrow" />
            </div>
        </div>
    </div>
</template>
<script>
import { getStaffId } from '../api/index';
import { getCookie } from '@/utils/cookies.js';
export default {
    props: {
        overViewDataLeftNum: {
            type: Number,
            default: 0
        },
        overViewDataRightNum: {
            type: Number,
            default: 0
        },
        overViewDataLeftTitle: {
            type: String,
            default: '有效客户'
        },
        overViewDataRightTitle: {
            type: String,
            default: '客户总数'
        },
        compareYesterday: {
            type: Number,
            default: 0
        },
        isShowArrowIcon: {
            type: Boolean,
            default: true
        },
        isGray: {
            type: Boolean,
            default: false
        },
        isBlock: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            jumpParams: {}
        };
    },
    methods: {
        goCustomerList() {
            this.$router.push({
                path: '/staff_promotion/customer_list'
            });
        },
        async goMerchantList() {
            if (this.isGray) {
                return this.$router.push({ name: 'customerList', query: { type: 'merchant' } });
            }
            let appId = getCookie('with_app_id');
            const clientType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
                ? 'Mobile'
                : 'Desktop';
            console.log('useragent', navigator.userAgent);
            let client = clientType === 'Mobile' ? 0 : 1;
            let res = await getStaffId({
                user_id: localStorage.getItem('b_user_id'),
                from: client,
                app_id: appId
            });
            const { key, wework_staff_id: account, host } = res.data;

            if (!client) {
                console.log('移动端', navigator.userAgent);
                window.location.href = `${host}api/crm/market_login_business_list?key=${key}&account=${account}&from=1`;
            } else {
                console.log('Pc端', navigator.userAgent);
                window.location.href = `${host}crm/market_login_business_list?key=${key}&account=${account}&from=1&app_id=${appId}`;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.customers {
    background: #fff;
    border-radius: 0.24rem;
    display: flex;
    justify-content: space-between;
    color: #b3b3b3;
    width: 100%;
    padding: 0.36rem 0 0.32rem;
    max-height: 1rem;
    & > .left {
        margin-left: 0.96rem;
        font-size: 0.28rem;
    }
    & > .center {
        width: 0.02rem;
        background: #eeeeee;
        height: 0.9rem;
    }
    & > .right {
        width: 34%;
    }
    .left-wrap,
    .right-wrap {
        display: flex;
        align-items: flex-end;
    }
    .no-show {
        color: rgba(51, 51, 51, 1);
        font-size: 0.28rem;
        margin-top: 3px;
    }
    .title {
        font-size: 0.24rem;
        color: #333333;
        font-family: 'PingFang SC';
        line-height: 0.32rem;
        height: 0.32rem;
    }
    .text {
        color: rgba(51, 51, 51, 1);
        font-size: 0.28rem;
        margin-left: 0.08rem;
        font-family: 'PingFang SC';
    }
    .num {
        color: #333333;
        font-size: 0.48rem;
        line-height: 0.48rem;
        font-weight: 700;
        font-family: 'D-DIN-Bold';
    }
    .arrow {
        margin-left: 0.08rem;
        top: -2px !important;
    }
    .van-icon {
        top: 0.04rem;
    }
}
.data-left {
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
        margin-left: 0;
        text-align: center;
        .left-wrap {
            justify-content: center;
        }
    }
}
</style>
