<template>
    <div class="fixed staff-promotion" v-loading="isLoading">
        <template v-if="hasPromotionAuth && !isLoading">
            <div class="personal-info">
                <personal-information
                    :userName="userInfo.name"
                    :userAvatar="userInfo.avatar"
                    :company="userInfo.company"
                    :userId="userInfo.user_id"
                    :isBlock="isBlock"
                    :promotionNotice="promotionData.promotionNotice"
                    :qrcodeUrl="qrcodeUrl"
                    :promotionSummary="promotionData.promotionSummary"
                />
                <div class="overViewData">
                    <customerDataOverview
                        :isGray="isGray"
                        :isBlock="isBlock"
                        overViewDataLeftTitle="锁定微信"
                        overViewDataRightTitle="获取手机号"
                        :overViewDataLeftNum="promotionData.totalCustomerNum"
                        :overViewDataRightNum="promotionData.totalMerchantNum"
                    />
                </div>
            </div>
            <div class="activity-title">
                <span class="activity">活动</span>
                <span class="more-activity" @click="goActivityList()">查看更多</span>
            </div>
            <activityList v-if="activityList.length > 0" :activityList="activityList" />
            <div class="invalidPromotion" v-else>
                <img src="@/assets/images/promotion_invalid.png" alt="" />
                <p>暂无数据</p>
            </div>
        </template>
        <div class="empty" v-else>
            <img v-show="showSadGoose" src="@/assets/images/xiaoe-empty.png" alt="" />
            <p class="text">{{ errMsg }}</p>
        </div>
        <xiaoEFooter
            :class="{
                'xiaoE-footer': !hasPromotionAuth || activityList.length === 1
            }"
        />
    </div>
</template>
<script>
import personalInformation from '@/views/staffPromotion/components/personalInformation';
import activityList from '@/views/staffPromotion/components/activityList';
import customerDataOverview from '@/views/staffPromotion/components/customerDataOverview';
import xiaoEFooter from '@/views/staffPromotion/components/footer';
import { fetchPromotionCenter } from '@/views/staffPromotion/api';
import permission from '@/mixins/permission';
import { getCookie } from '@/utils/cookies';

export default {
    mixins: [permission],
    components: {
        personalInformation,
        activityList,
        customerDataOverview,
        xiaoEFooter
    },
    data() {
        return {
            promotionData: {
                promotionNotice: '',
                promotionSummary: '',
                effectiveCustomerNum: 0,
                todayNewCustomer: 0,
                todayNewMerchant: 0
            },
            showSadGoose: false,
            userInfo: {},
            activityList: [],
            hasPromotionAuth: true,
            isLoading: false,
            errMsg: '',
            qrcodeUrl: '',
            isGray: false,
            isBlock: false
        };
    },
    async created() {
        this.isLoading = true;
        await this.hasPermission((data) => {
            const { is_distributor, status, manage_info } = data;
            if (is_distributor === 0 && status === 2) {
                this.$router.replace({
                    name: 'noPermissionPage',
                    query: {
                        manager: manage_info.name
                    }
                });
            }
            if (is_distributor === 1 && status === 2) {
                this.$router.replace({
                    name: 'noPermissionPage',
                    query: {
                        manager: manage_info.name
                    }
                });
            }
            if (is_distributor === 0 && status === 0) {
                this.$router.replace({
                    name: 'noPermissionPage',
                    query: {
                        manager: manage_info.name
                    }
                });
            }
        });
        this.initData();
    },
    methods: {
        initData() {
            this.isLoading = true;
            let user_id = localStorage.getItem('userId');
            fetchPromotionCenter({ user_id })
                .then((res) => {
                    const { code, data, msg } = res;
                    if (code === 0) {
                        this.getDistributeSeting(data);
                    } else if (code === 500) {
                        this.isLoading = false;
                        this.hasPromotionAuth = false;
                        this.errMsg = '系统错误（500）';
                        this.showSadGoose = true;
                    } else {
                        this.isLoading = false;
                        this.hasPromotionAuth = false;
                        this.errMsg = msg;
                        this.showSadGoose = true;
                    }
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log('err: ', err);
                    this.hasPromotionAuth = false;
                    this.errMsg = '系统错误（500）';
                });
        },

        // 获取已设置的信息
        getDistributeSeting(data) {
            const params = {
                app_id: getCookie('with_app_id'),
                user_id: localStorage.getItem('userId')
            };
            this.$ajax('get_distribute_seting', params)
                .then((res) => {
                    this.isLoading = false;
                    if (res.data.code === 0) {
                        // 同步头像和名字为推广设置页所设置的
                        this.userInfo.name = res.data.data.user_name;
                        this.userInfo.avatar = res.data.data.pic_url;
                        this.handlePromotionData(data);
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },

        handlePromotionData(data) {
            const {
                title,
                summary,
                profile,
                effective_customer_num,
                today_add_customer_num,
                activity_list,
                exclusive_channel_code,
                today_add_merchant_num,
                is_gray_business_opportunities,
                open_wework_distribute,
                total_customer_num, // 总商机数
                total_merchant_num // 总锁客数
            } = data;
            this.isGray = is_gray_business_opportunities;
            this.isBlock = open_wework_distribute || false;
            this.qrcodeUrl = exclusive_channel_code;
            if (this.userInfo.name || this.userInfo.avatar) {
                profile.name = this.userInfo.name ? this.userInfo.name : profile.name;
                profile.avatar = this.userInfo.avatar ? this.userInfo.avatar : profile.avatar;
            }
            this.userInfo = profile;
            this.activityList = activity_list;
            this.promotionData = {
                promotionNotice: title,
                promotionSummary: summary,
                effectiveCustomerNum: effective_customer_num,
                todayNewCustomer: today_add_customer_num,
                totalCustomerNum: total_customer_num,
                totalMerchantNum: total_merchant_num,
                todayNewMerchant: today_add_merchant_num
            };
        },
        goActivityList() {
            this.$router.push({
                path: '/staff_promotion/promotion_activity'
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.staff-promotion {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .personal-info {
        position: relative;
        height: 4.52rem;
    }
    .overViewData {
        position: absolute;
        top: 3.68rem;
        left: 0rem;
        width: 100%;
        padding: 0 0.32rem;
        box-sizing: border-box;
    }
    .activity-title {
        display: flex;
        justify-content: space-between;
        margin: 1.32rem 0.4rem 0.2rem;
        height: 0.5rem;
        line-height: 0.5rem;
        span {
            font-size: 0.24rem;
            color: #999999;
        }
        & > .activity {
            font-size: 0.32rem;
            color: #333;
            font-weight: 500;
        }
        .more-activity {
            color: #1472ff;
            background: rgba(20, 114, 255, 0.1);
            padding: 0 0.16rem;
            border-radius: 4px;
        }
    }
    .xiaoE-footer {
        position: absolute;
        bottom: 0.5rem;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}
.empty {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 5rem;
    img {
        width: 2.88rem;
        height: 2.88rem;
        text-align: center;
    }
    .text {
        text-align: center;
        color: #999999;
        font-size: 0.28rem;
    }
    .manage {
        margin-top: 0.28rem;
        color: #333333;
    }
}
.invalidPromotion {
    text-align: center;
    img {
        width: 2rem;
        height: 2rem;
    }
    p {
        text-align: center;
        color: #999999;
        font-size: 0.28rem;
    }
}
</style>
