<template>
    <div class="personal-info">
        <div class="qrcode-icon" v-if="qrcodeUrl">
            <img :src="require('@/assets/images/qrcode-icon.png')" alt="icon" @click="showQrCode = true" />
            <van-popup
                v-model="showQrCode"
                :style="{
                    minHeight: '312px',
                    width: '288px',
                    borderRadius: '8px'
                }"
                closeable
                class="van-popup"
            >
                <h4>
                    <open-data :id="userId" :name="userName" />
                    的专属码
                </h4>
                <div>
                    <vue-qr
                        ref="qr"
                        :text="qrcodeUrl"
                        :size="160"
                        :margin="6"
                        :backgroundColor="'#EEEEEE'"
                        :callback="qrcodeCallBack"
                    />
                </div>
                <span class="tips">客户扫码到官网注册后与你绑定</span>
            </van-popup>
        </div>
        <div class="avatar">
            <img v-default-avatar :src="userAvatar" />
        </div>
        <div class="promoters-tips" @click="goPromotersTips()" v-if="promotionNotice">
            {{ promotionNotice }}
            <van-icon name="arrow" size="0.24rem" color="#F9FED1" class="arrow" />
        </div>
        <div class="username">{{ userName }}</div>
        <div class="company">
            <img :src="require('@/assets/images/companyIcon.png')" alt="icon" />
            {{ company }}
        </div>
        <div class="personal-info-footer">
            <div class="settings" @click="toPersonalSettings" v-if="isBlock">
                <div class="dotted" v-if="isShowDot"></div>
                推广引流设置
            </div>
            <div class="promoters-invite" v-if="specialAppInfo" @click="goInvitePage">邀请加入</div>
        </div>
    </div>
</template>
<script>
import openData from '@/components/openData';
import VueQr from 'vue-qr';
import { Toast } from 'vant';
import { getCurrentAppId, getSpecialAppInfo } from '@/utils/specialApp';

export default {
    props: {
        userName: {
            type: String,
            require: true
        },
        userAvatar: {
            type: String,
            default: ''
        },
        company: {
            type: String,
            default: ''
        },
        promotionNotice: {
            type: String,
            default: '推广员须知'
        },
        userId: {
            type: String,
            default: ''
        },
        qrcodeUrl: {
            type: String,
            default: ''
        },
        promotionSummary: {
            type: String,
            default: ''
        },
        isBlock: {
            type: Boolean,
            default: false
        }
    },
    components: {
        openData,
        VueQr
    },
    data() {
        return {
            showQrCode: false,
            qrCodeUrlBlob: '',
            specialAppInfo: null,
            isShowDot: true,
            currentAppId: ''
        };
    },
    methods: {
        goPromotersTips() {
            console.log('跳转参数', this.promotionSummary);
            this.$router.push({
                name: 'promotionTips',
                params: {
                    summary: this.promotionSummary
                }
            });
        },
        qrcodeCallBack(url) {
            console.log('url: ', url);
        },
        goInvitePage() {
            //  新改动，暂时都不跳转，以后再梳理优化
            Toast({
                message: '该功能正在开发中哦~~'
            });
            /*this.$router.push({
                name: 'invitePosters',
                query: {
                    invite_info: encodeURIComponent(
                        JSON.stringify({
                            is_inviter: true,
                            share_user: this.userName,
                            share_app_id: this.specialAppInfo.appId
                        })
                    )
                }
            });*/
        },
        toPersonalSettings() {
            if (!localStorage.getItem('showDot')) {
                localStorage.setItem('showDot', true);
                this.isShowDot = false;
            }
            console.log('推广引流设置跳转路由');
            // 推广引流设置跳转路由
            this.$router.push('/staff_promotion/distributeSeting');
        }
    },
    created() {
        this.currentAppId = getCurrentAppId();
        this.specialAppInfo = getSpecialAppInfo(this.currentAppId);
    },
    mounted() {
        if (localStorage.getItem('showDot')) {
            this.isShowDot = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.personal-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.48rem;
    margin-bottom: 0.4rem;
    background: url('../../../assets/images/backgroundImage.png') center no-repeat;
    background-size: 100% 100%;
    padding: 0 0.32rem;
    position: relative;
    .qrcode-icon {
        position: absolute;
        top: 0.74rem;
        left: 0.32rem;
        & > img {
            width: 0.28rem;
            height: 0.28rem;
        }
        .van-popup {
            text-align: center;
            h4 {
                color: #333333;
                font-size: 0.32rem;
                text-align: center;
                margin: 40px 0 0.32rem;
            }
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    background: #eeeeee;
                    border-radius: 8px;
                }
            }
            .tips {
                color: #999999;
                font-size: 0.24rem;
                text-align: center;
                display: inline-block;
                padding: 0.38rem 0;
            }
        }
    }
    .avatar img {
        width: 1.12rem;
        height: 1.12rem;
        border-radius: 50%;
        margin-top: 0.36rem;
        box-sizing: border-box;
        border: 0.03rem solid #f9fed1;
    }
    .promoters-tips {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0.7rem;
        right: 0.32rem;
        color: rgba(249, 254, 209, 1);
        font-size: 0.24rem;
        line-height: 0.36rem;
        font-family: 'PingFang SC';
        height: 0.36rem;
        text-align: right;
        .arrow {
            margin-left: 2px;
        }
    }
    .personal-info-footer {
        display: flex;
        align-items: center;
    }
    .username {
        width: 6.86rem;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 0.36rem;
        font-weight: 700;
        font-family: 'PingFang SC';
        line-height: 0.48rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .company {
        font-size: 0.28rem;
        color: rgba(255, 255, 255, 1);
        opacity: 0.699999988079071;
        line-height: 0.4rem;
        margin-bottom: 0.32rem;
        img {
            position: relative;
            top: 0.06rem;
            width: 0.32rem;
            height: 0.32rem;
        }
    }
    .settings {
        position: relative;
        width: 1.92rem;
        height: 0.56rem;
        border-radius: 0.4rem;
        background: rgba(249, 254, 209, 1);
        color: rgba(249, 112, 67, 1);
        font-size: 0.24rem;
        font-family: 'PingFang SC';
        display: flex;
        align-items: center;
        justify-content: center;
        .dotted {
            position: absolute;
            top: 0;
            right: 0;
            width: 0.16rem;
            height: 0.16rem;
            border-radius: 0.08rem;
            background: rgba(255, 71, 71, 1);
        }
    }
    .promoters-invite {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.32rem;
        width: 1.92rem;
        height: 0.56rem;
        border-radius: 0.4rem;
        border: 1px solid #f9fed1;
        color: rgba(249, 254, 209, 1);
        font-size: 0.24rem;
    }
}
</style>
